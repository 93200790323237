import {api} from '@eksab/api';
import {getCountry, getLanguage} from '@eksab/util';
import {useQuery} from './useQuery';
import {UseQueryOptions, QueryFunctionContext} from '@tanstack/react-query';

type Settings = {
  referral_message: string;
  referral_reward_coins: string;
  referral_reward_percentage_for_payments: number;
  referral_reward_cap_for_payments: number | null;
  enable_phone_verification: boolean;
  eksab_share: 0 | 1;
  landing_page: string;
  sign_up_reward_coins: number;
  prize_dispatch_time: number;
  coins_redemption_bonus_percentage: number;
  licence_details: {
    date: string;
    number: string;
  };
  buy_coins_after_sign_up: boolean;
  phone_number_regex: string;
  apk_link?: string;
  app_store_link?: string;
  available_formations: Formation[];
  featured_content_banner: Media | null;
};

const queryKey = ['app-settings'];

const queryFn = (context: QueryFunctionContext) => {
  const locale = context.queryKey[context.queryKey.length - 1] as Locale;
  return api
    .get('settings', {headers: {'X-REGION': getCountry(locale), 'X-Locale': getLanguage(locale)}})
    .then((res) => res.data.data);
};

export const useAppSettingsQuery = (options?: UseQueryOptions<Settings>) =>
  useQuery<Settings>(queryKey, queryFn, {
    staleTime: 1000 * 60 * 60 * 3, // Refetch only every 3 hours to preserve user data
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });

useAppSettingsQuery.queryKey = queryKey;
useAppSettingsQuery.queryFn = queryFn;
