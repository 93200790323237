import {Icon, type IconProps} from '@chakra-ui/icon';

import {FantasyIcon} from '@eksab/features/competition/icons/FantasyIcon';
import {PredictionsIcon} from '@eksab/features/competition/icons/PredictionsIcon';
import {TriviaIcon} from '@eksab/features/trivia/icons/TriviaIcon';
import {TriviaFilmIcon} from '@eksab/features/trivia/icons/TriviaFilmIcon';
import {AwardIcon} from '@eksab/features/profile/icons/AwardIcon';

const Home = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 25 24" fill="none" color="#6C6C6C" {...props}>
    <path
      d="M21.5 15.3969C21.5 19.9329 20.5 20.4329 12.5 20.4329C4.50003 20.4329 3.49999 19.4329 3.50003 15.3969C3.50006 13.2556 3.5 13.7515 3.5 11.8773C3.5 10.003 4.07474 8.93288 7.44411 6.22235C8.85369 5.0884 10.0725 4.17162 10.9601 3.52398C11.8925 2.84364 13.1264 2.81735 14.0609 3.49475C14.8853 4.09227 16.044 4.97476 17.5559 6.22235C21 9.06446 21.5 9.93288 21.5 11.8773C21.4999 15.6374 21.5 12.2556 21.5 15.3969Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M9.5 17.4329C9.875 15.9329 9.5 14.4329 12.5 14.4329C15.5 14.4329 15.125 15.9329 15.5 17.4329"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

const StoreIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M10.0265 5.85055C14.5414 5.85055 11.5528 5.85055 15.5751 5.85055C19.5973 5.85055 20.6993 5.85055 19.5973 11.3244C18.48 16.8741 17.3827 17.3097 13.5639 16.8741C7.45837 16.1775 7.84206 15.9843 6.52502 10.2145C4.8782 3 5.96972 3 2 3"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 21C8.77614 21 9 20.7761 9 20.5C9 20.2239 8.77614 20 8.5 20C8.22386 20 8 20.2239 8 20.5C8 20.7761 8.22386 21 8.5 21Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 21C16.7761 21 17 20.7761 17 20.5C17 20.2239 16.7761 20 16.5 20C16.2239 20 16 20.2239 16 20.5C16 20.7761 16.2239 21 16.5 21Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

const ProfileIcon = (props: IconProps) => (
  <Icon width="25px" height="24px" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M19.5 18.5C19.5 20.0294 16.5 21 12.5 21C8.5 21 5.5 20.0294 5.5 18.5C5.5 16.2692 9 15 12.5 15C16 15 19.5 16.5 19.5 18.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 12C14.9853 12 17 9.98528 17 7.5C17 5.01472 14.9853 3 12.5 3C10.0147 3 8 5.01472 8 7.5C8 9.98528 10.0147 12 12.5 12Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export const icons = {
  Home,
  FantasyIcon,
  PredictionsIcon,
  TriviaIcon,
  TriviaFilmIcon: TriviaFilmIcon,
  StoreIcon,
  ProfileIcon,
  AwardIcon,
};
