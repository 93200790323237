import Script from 'next/script';

export function AdsenseScript() {
  return (
    <Script
      strategy="lazyOnload"
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1612663164003674"
      crossOrigin="anonymous"
    />
  );
}
