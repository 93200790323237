import Script from 'next/script';

export const UniversalAnalyticsScript = () => (
  <>
    <Script src="https://www.googletagmanager.com/gtag/js?id=UA-214286717-1" strategy="lazyOnload" />
    <Script
      id="gtm"
      dangerouslySetInnerHTML={{
        __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'UA-214286717-1');
`,
      }}
    />
  </>
);
