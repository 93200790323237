import {useEffect, useCallback} from 'react';
import {type Socket, io} from 'socket.io-client';
import {create} from 'zustand';

import {config} from '@eksab/config';
import type {MilestoneDetails} from '@eksab/features/profile/badges/types';

import {useAccessToken} from './useAccessToken';

interface ClientToServerEvents {}

export interface PrizeAttachedPayload {
  challenge_id: string;
  winner_attempt_id: string;
  user_prize_id: number;
}

export interface ChallengeClosedPayload {
  challenge_id: string;
  winner_attempt_id: string;
}

export interface ServerToClientEvents {
  'packages.earned_badges.user_won_badge': (milestone: MilestoneDetails) => void;
  'trivia.challenges.prize_attached': (payload: PrizeAttachedPayload) => void;
  'trivia.challenges.closed': (payload: ChallengeClosedPayload) => void;
}

type Event = keyof ServerToClientEvents;

type Listener<E extends Event> = ServerToClientEvents[E];

type AppSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

let socket: AppSocket | null = null;

export const useSocket = () => {
  const {data: token} = useAccessToken();

  useEffect(() => {
    if (!token) return;

    if (socket?.connected) return;

    socket = io(config.socketURL, {
      extraHeaders: {Authorization: `Bearer ${token}`},
    });

    return () => {
      socket?.disconnect();
    };
  }, [token]);

  const subscribe = useCallback(<E extends Event>(event: E, listener: Listener<E>) => {
    socket?.on(event as Event, listener);

    return () => {
      socket?.off(event as Event, listener);
    };
  }, []);

  const unsubscribe = useCallback((event: Event) => socket?.off(event), []);

  return {
    isConnected: socket?.connected,
    subscribe,
    unsubscribe,
  };
};

interface SocketStoreActions {
  reset: () => void;
}

interface SocketStoreState {
  milestone: MilestoneDetails | null;
}

type SocketStore = SocketStoreState & SocketStoreActions;

const initialState: SocketStoreState = {
  milestone: null,
};

export const useSocketStore = create<SocketStore>((set) => ({
  ...initialState,
  reset: () => set(initialState),
}));
