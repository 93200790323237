import NextLink from 'next/link';
import {Link, LinkProps, useToken} from '@chakra-ui/react';
import {useTranslate} from '@eksab/i18n';

export function DownloadAppButton(props: LinkProps) {
  const t = useTranslate();

  const [green400Token, yellow400Token] = useToken('colors', ['green.400', 'yellow.400']);

  return (
    <Link
      as={NextLink}
      href="/download"
      pos="relative"
      isolation="isolate"
      display="flex"
      alignItems="center"
      minH="8"
      px="3"
      py="1.5"
      color="green.400"
      fontWeight="600"
      fontSize="sm"
      textAlign="center"
      bg={`linear-gradient(${yellow400Token}, ${green400Token})`}
      rounded="full"
      _before={{
        content: '""',
        position: 'absolute',
        inset: '1px',
        zIndex: -1,
        rounded: 'inherit',
        bg: '#2a2e3a',
      }}
      _hover={{textDecor: 'none', color: 'white'}}
      {...props}
    >
      {t('app.download')}
    </Link>
  );
}
