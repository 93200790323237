import {useEffect} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {Box, Container, Flex, Link, type LinkProps, Spacer} from '@chakra-ui/layout';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';
import {Button, IconButton, type IconButtonProps} from '@chakra-ui/button';
import {Image} from '@chakra-ui/image';
import {create} from 'zustand';

import {useTranslate} from '@eksab/i18n';
import {px} from '@eksab/theme';
import {useMeQuery} from '@eksab/hooks/useMeQuery';
import {useShouldPromoteMobileApp} from '@eksab/hooks/useShouldPromoteMobileApp';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {MenuIcon} from '@eksab/assets/icons/MenuIcon';
import {getCountry, getLanguage} from '@eksab/util/helpers';

import {useHeaderHeight} from './useHeaderHeight';
import {UserInfo} from './UserInfo';
import {DownloadAppButton} from './DownloadAppButton';
import {icons} from './icons';
import {NewBadge} from '../NewBadge';

const HamburgerMenu = dynamic<{isOpen: boolean; closeMenu: () => void}>(() =>
  import('../HamburgerMenu').then((module) => module.HamburgerMenu),
);

const useHeaderVisibility = create<{
  isHeaderVisible: boolean;
  hideHeader: () => void;
  showHeader: () => void;
}>((set) => ({
  isHeaderVisible: true,
  hideHeader: () => set({isHeaderVisible: false}),
  showHeader: () => set({isHeaderVisible: true}),
}));

export function RemoveAppHeader() {
  const settings = useRegionSettings().data;
  const {showHeader, hideHeader} = useHeaderVisibility();
  useEffect(() => {
    hideHeader();
    return () => {
      showHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

export function AppHeader() {
  const router = useRouter();
  const {isOpen, onClose, onOpen} = useDisclosure();

  const t = useTranslate();
  const isHeaderVisible = useHeaderVisibility((state) => state.isHeaderVisible);
  const {data: me} = useMeQuery();
  const shouldShowGetAppButton = useShouldPromoteMobileApp();

  const {data: regionSettings} = useRegionSettings();
  const headerHight = useHeaderHeight();
  return (
    <Container maxW="unset" id="header" px="none">
      {isHeaderVisible && (
        <Container maxW="unset" bgColor="gray.900" px="none">
          <HamburgerMenu isOpen={isOpen} closeMenu={onClose} />

          <Container
            as="header"
            alignItems="center"
            height={headerHight}
            px={[px[0], null, '3.75rem']}
            bgColor={['white', null, null, 'gray.900']}
          >
            <Flex gap="1" align="center" me={[1, null, null, 10]}>
              <MenuIcon
                display={['inline-block', null, null, 'none']}
                transform="auto"
                scaleX={getLanguage(router.locale) === 'ar' ? -1 : 1}
                cursor="pointer"
                onClick={onOpen}
              />

              <Flex
                as={NextLink}
                title={t('app.name')}
                href="/"
                onClick={() => mixpanel?.track('Button Clicked', {button: 'header.logo'})}
              >
                <Image
                  display={['none', null, null, 'block']}
                  width="auto"
                  height="6"
                  p="1"
                  src="/logos/eksab.svg"
                  alt={t('app.name')}
                />

                <icons.Logo display={{lg: 'none'}} width="3.75rem" height="auto" p="1" />
              </Flex>
            </Flex>

            <Flex display={['none', null, null, 'flex']} align="center" as="nav" gap="1.125rem">
              <NextLink href="/" aria-label={t('app.home')}>
                <icons.HomeIcon active={router.pathname === '/'} />
              </NextLink>

              <NavRoute name={t('play')} route="/play">
                <NewBadge left="50%" translateX="-50%" />
              </NavRoute>

              <NavRoute name={t('app.coins')} route="/store/coins" />

              <NavRoute name={t('app.profile')} route={me ? '/profile' : '/login?from=/profile'} />

              {getLanguage(router.locale) === 'ar' && <NavRoute name={t('app.news')} route="/news" />}

              {shouldShowGetAppButton && <DownloadAppButton />}
            </Flex>

            <Spacer />

            <LanguageSwitch mx="1.5" display={['none', null, null, 'flex']} />

            <Box display="flex" alignItems="center">
              {me ? <UserInfo me={me} /> : <GuestLinks />}
            </Box>
          </Container>
        </Container>
      )}
    </Container>
  );
}

interface NavRouteProps extends LinkProps {
  name: string;
  route: string;
}

const NavRoute = ({route, name, ...props}: NavRouteProps) => {
  const router = useRouter();
  const isActive = router.route.startsWith(route);

  return (
    <Link
      as={NextLink}
      href={route}
      pos="relative"
      color={isActive ? 'green.400' : 'gray.500'}
      fontWeight={isActive ? 900 : 500}
      textAlign="center"
      _hover={{color: 'green.400'}}
      lineHeight="6"
      {...props}
    >
      {name}

      {props.children}
    </Link>
  );
};

const GuestLinks = () => {
  const t = useTranslate();
  const router = useRouter();

  return (
    <Flex alignItems="center">
      <NextLink
        href={{pathname: '/register', query: {from: router.query?.from ?? encodeURIComponent(router.asPath)}}}
        passHref
        legacyBehavior
      >
        <Button as="a" h="9" px="4" me="3" textTransform="none" fontSize="md" fontWeight="bold">
          {t('app.actions.register')}
        </Button>
      </NextLink>

      <NextLink
        href={{pathname: '/login', query: {from: router.query?.from ?? encodeURIComponent(router.asPath)}}}
        passHref
        legacyBehavior
      >
        <Link fontSize="sm" fontWeight={['600', '800']} color="#8F8F8F">
          {t('app.actions.login')}
        </Link>
      </NextLink>
    </Flex>
  );
};

const LanguageSwitch = (props: Omit<IconButtonProps, 'aria-label'>) => {
  const router = useRouter();
  const lang = getLanguage(router.locale);
  const newLocale = `${getLanguage(router.locale) === 'ar' ? 'en' : 'ar'}-${getCountry(router.locale)}`;
  return (
    <NextLink passHref href={{pathname: router.pathname, query: router.query}} locale={newLocale} legacyBehavior>
      <IconButton
        as="a"
        icon={<icons.LanguageSwitchIcon lang={lang === 'ar' ? 'en' : 'ar'} />}
        variant="unstyled"
        minW="unset"
        aria-label="language switch"
        boxSize="8"
        borderRadius="full"
        {...props}
      />
    </NextLink>
  );
};
