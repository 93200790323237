import type {AxiosError} from 'axios';
import {useQueryClient, type QueryKey, type QueryFunctionContext} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {getCountry, getLanguage} from '@eksab/util/helpers';

import {games} from '@eksab/features/play/const';
import type {Game, GameConfig} from '@eksab/features/play/types';

import {useQuery} from './useQuery';

type FeatureFlag =
  | 'tactic_enabled'
  | '2_sides_document'
  | 'payment_methods.fawry'
  | 'payment_methods.cc'
  | 'payment_methods.wallets'
  | 'coin_packages_enabled'
  | 'show_licence_details'
  | 'badge_packages_enabled'
  | 'show_prize_redemption_legal_forms'
  | 'show_latest_winners'
  | 'show_groups'
  | 'shipping_available'
  | 'phone_number_verification_enabled';

interface Settings extends Record<FeatureFlag, boolean> {
  active_games: Game[];
}

interface MappedSettings extends Record<FeatureFlag, boolean> {
  active_games: GameConfig[];
}

export interface RegionSettings {
  name: string;
  iso_code: Region;
  currency: string;
  settings: Settings;
}

interface MappedRegionSettings extends Omit<RegionSettings, 'settings'> {
  settings: MappedSettings;
}

const queryKey = 'region-settings';

const queryFn = (context: QueryFunctionContext<QueryKey>) => {
  const locale = context.queryKey[context.queryKey.length - 1] as string;

  return api
    .get<{data: RegionSettings}>('v3/regions/current', {
      headers: {'X-REGION': getCountry(locale), 'X-Locale': getLanguage(locale)},
    })
    .then((res) => res.data.data);
};

export function useRegionSettings() {
  return useQuery<RegionSettings, AxiosError, MappedRegionSettings>([queryKey], queryFn, {
    select: (data) => {
      const active_games = data?.settings.active_games.map((game) => games[game]).filter(Boolean);

      return {
        ...data,
        settings: {
          ...data.settings,
          active_games,
        },
      };
    },
  });
}

export function useGetRegionSettingsQueryData() {
  const queryClient = useQueryClient();
  return () => queryClient.getQueryData<RegionSettings>([queryKey], {exact: false});
}

useRegionSettings.queryKey = queryKey;
useRegionSettings.queryFn = queryFn;
