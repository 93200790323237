import {ReactElement, ReactNode, useEffect, useState} from 'react';
import type {NextPage} from 'next/types';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import type {AppProps} from 'next/app';
import {IntlProvider} from 'react-intl';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {ChakraProvider} from '@chakra-ui/react';
import {QueryClientProvider, QueryClient, Hydrate, type DehydratedState} from '@tanstack/react-query';
import {DirectionProvider} from '@radix-ui/react-direction';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'nprogress/nprogress.css';
import numeral from 'numeral';

import {theme} from '@eksab/theme';
import {config} from '@eksab/config';
import {messages} from '@eksab/i18n';
import {useAccessToken} from '@eksab/hooks/useAccessToken';
import {useWebViewData} from '@eksab/hooks/useWebView';
import {setApiLocale, setApiToken} from '@eksab/api';
import {
  AdsenseScript,
  GooglePixelScript,
  MetaPixelScript,
  MixPanelScript,
  TwitterPixelScript,
  UniversalAnalyticsScript,
} from '@eksab/lib';
import {Main} from '@eksab/components/Main';
import {AppMobileNav} from '@eksab/components/nav-bar/MobileNav';
import {OpenInApp} from '@eksab/components/OpenInApp';
import {CPALicense} from '@eksab/components/CPALicense';
import {AppHeader} from '@eksab/components/header/Header';
import {AppFooter} from '@eksab/components/footer/Footer';

import {getLanguage} from '@eksab/util/helpers';

dayjs.extend(duration);
dayjs.extend(relativeTime);

type PageProps = {token?: {accessToken: string}; dehydratedState: DehydratedState};
type NextPageWithLayout = NextPage<PageProps> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

if (typeof window !== 'undefined') {
  window.fbq = undefined;
  window.mixpanel = undefined;
  window.gtag = undefined;
  window.twq = undefined;
}

const handleRouteChange = (url: string) => {
  fbq?.('track', 'PageView');
  gtag?.('set', 'page_path', url);
  gtag?.('event', 'page_view');
};

const TopProgressBar = dynamic(() => import('@eksab/components/TopProgressBar'), {ssr: false});

export default function MyApp({Component, pageProps, router}: AppPropsWithLayout) {
  const locale = router.locale as Locale;
  const {webViewData} = useWebViewData();

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
  }, [router.events]);

  const [queryClient] = useState(() => {
    const queryClient = new QueryClient({defaultOptions: {queries: {staleTime: 5 * 60 * 1000}}});

    const token = pageProps.token?.accessToken;
    if (token) {
      queryClient.setQueryData(useAccessToken.queryKey, token);
      setApiToken(token);
    }
    return queryClient;
  });

  const direction = getLanguage(locale) === 'ar' ? 'rtl' : 'ltr';
  const intlLocale = getLanguage(locale) === 'ar' ? 'ar-EG' : 'en';

  const appTheme = theme(direction);
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  setApiLocale(locale);
  dayjs.locale(getLanguage(locale));
  numeral.locale(getLanguage(locale));

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#5AC57B" />
      </Head>

      <TopProgressBar />

      <QueryClientProvider client={queryClient}>
        {config.appEnv === 'production' && (
          <>
            <TwitterPixelScript />
            <MixPanelScript />
            <MetaPixelScript />
            <AdsenseScript />
            <UniversalAnalyticsScript />
            <GooglePixelScript />
            {/* <ZendeskScript /> */}
          </>
        )}

        <Hydrate state={pageProps.dehydratedState}>
          <DirectionProvider dir={direction}>
            <ChakraProvider theme={appTheme}>
              <IntlProvider locale={intlLocale} messages={messages[getLanguage(locale)]}>
                <OpenInApp />

                {!webViewData?.hideHeader && <CPALicense />}
                {!webViewData?.hideHeader && <AppHeader />}

                <Main>{getLayout(<Component {...pageProps} />)}</Main>

                <AppFooter />

                <AppMobileNav />
              </IntlProvider>
            </ChakraProvider>
          </DirectionProvider>
        </Hydrate>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}
