import {create} from 'zustand';

export const useMobileNavHeight = create<{
  bottomNavHeight: string;
  reset: () => void;
  setBottomNavHeight: (height: string) => void;
}>((set) => ({
  bottomNavHeight: '4.7rem',
  setBottomNavHeight: (height: string) => set({bottomNavHeight: height}),
  reset: () => set({bottomNavHeight: '4.7rem'}),
}));
