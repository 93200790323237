import {AxiosError, AxiosResponse} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {config} from '@eksab/config';
import * as cache from '@eksab/cache';
import {api, setApiToken} from '@eksab/api';
import {useFetchMe} from '@eksab/hooks/useMeQuery';
import {useSetAccessToken} from '@eksab/hooks/useAccessToken';

import {useAuthStore} from '../state';

type TokenLoginParams = {
  code: string;
};

interface TokenLoginRequestParams {
  grant_type: 'user-auth-code';
  client_id: '2';
  scope: 'user';
  code: string;
}

type TokenLoginReturn = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  extra: {
    additional: {
      region: Region;
    };
  };
};

export function useTokenLoginMutation(
  options?: UseMutationOptions<TokenLoginReturn, AxiosError, TokenLoginParams, TokenLoginRequestParams>,
) {
  const fetchMe = useFetchMe();
  const setAccessToken = useSetAccessToken();
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);

  return useMutation<TokenLoginReturn, AxiosError, TokenLoginParams, TokenLoginRequestParams>(
    ({code}) =>
      api
        .post<TokenLoginParams, AxiosResponse<TokenLoginReturn>, TokenLoginRequestParams>(
          '/api/login',
          {
            grant_type: 'user-auth-code',
            client_id: '2',
            scope: 'user',
            code,
          },
          {
            baseURL: config.baseURL,
          },
        )
        .then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        mixpanel?.track('Login');
        mixpanel?.track('WebView Login');

        const [{access_token}] = params;

        try {
          setLoggedIn(true);
          setAccessToken(access_token);
          setApiToken(access_token);

          const me = await fetchMe();
          mixpanel?.identify(`${me.id}`);
          mixpanel?.people.set({name: me.name, email: me.email, coins: me.coins});
          cache.setMe(me);

          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
}
