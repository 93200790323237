import {PropsWithChildren, useEffect} from 'react';
import dynamic from 'next/dynamic';
import {Box} from '@chakra-ui/layout';
import {useDisclosure} from '@chakra-ui/react-use-disclosure';

import {useHotJar} from '@eksab/hooks/useHotJar';
import {useSocket, useSocketStore} from '@eksab/hooks/useSocket';
import {useSyncWebViewAuth} from '@eksab/hooks/useWebView';

import {useMobileNavHeight} from './footer/useMobileNavHeight';

const NewlyEarnedBadgeModal = dynamic(() =>
  import('@eksab/features/profile/badges/components/NewlyEarnedBadgeModal').then((mod) => mod.NewlyEarnedBadgeModal),
);

export function Main({children}: PropsWithChildren<{}>) {
  const newlyEarnedBadgeDisclosure = useDisclosure();

  const mobileNavHeight = useMobileNavHeight((state) => state.bottomNavHeight);
  useHotJar();
  useSyncWebViewAuth();
  const {subscribe} = useSocket();

  useEffect(() => {
    const unsubscribe = subscribe('packages.earned_badges.user_won_badge', (milestone) => {
      useSocketStore.setState({milestone});
      newlyEarnedBadgeDisclosure.onOpen();
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box as="main" flex="1" display="flex" flexDir="column" pb={[mobileNavHeight, null, null, 0]}>
        {children}
      </Box>

      <NewlyEarnedBadgeModal {...newlyEarnedBadgeDisclosure} />
    </>
  );
}
