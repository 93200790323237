import {
  fantasyImage,
  fantasyIcon,
  tacticImage,
  tacticIcon,
  triviaSportsImage,
  triviaSportsIcon,
  triviaArtImage,
  triviaArtIcon,
  triviaKataflamImage,
  triviaDerbyImage,
  fantasySabahoKoraImage,
  triviaSportsSabahoKoraImage,
  triviaArtBeta3AflamImage,
  triviaSportsJosFootyImage,
  tacticErza3Image,
} from './icons';
import type {Game, GameConfig} from './types';

export const games = {
  fantasy: {
    name: 'fantasy',
    type: 'fantasy',
    logo: fantasyIcon,
    image: fantasyImage,
    route: 'fantasy',
  },
  tactic: {
    name: 'tactic',
    type: 'tactic',
    logo: tacticIcon,
    image: tacticImage,
    route: 'tactic',
  },
  'trivia-sports': {
    name: 'trivia-sports',
    type: 'trivia',
    topic: 'sports',
    logo: triviaSportsIcon,
    image: triviaSportsImage,
    route: 'trivia-sports',
  },
  'trivia-art': {
    name: 'trivia-art',
    type: 'trivia',
    topic: 'art',
    logo: triviaArtIcon,
    image: triviaArtImage,
    route: 'trivia-art',
  },
  'trivia-sports-derby': {
    name: 'trivia-sports-derby',
    type: 'trivia',
    topic: 'sports',
    partner: 'derby',
    logo: triviaDerbyImage,
    image: triviaDerbyImage,
    route: 'derby',
  },
  'trivia-art-kataflam': {
    name: 'trivia-art-kataflam',
    type: 'trivia',
    topic: 'art',
    partner: 'kataflam',
    logo: triviaKataflamImage,
    image: triviaKataflamImage,
    route: 'kataflam',
  },
  'fantasy-sabaho-kora': {
    name: 'fantasy-sabaho-kora',
    type: 'fantasy',
    partner: 'sabaho-kora',
    logo: fantasySabahoKoraImage,
    image: fantasySabahoKoraImage,
    route: 'sabaho-kora-fantasy',
  },
  'trivia-sports-sabaho-kora': {
    name: 'trivia-sports-sabaho-kora',
    type: 'trivia',
    topic: 'sports',
    partner: 'sabaho-kora',
    logo: triviaSportsSabahoKoraImage,
    image: triviaSportsSabahoKoraImage,
    route: 'sabaho-kora-trivia',
  },
  'tactic-erza3': {
    name: 'tactic-erza3',
    type: 'tactic',
    partner: 'erza3',
    logo: tacticErza3Image,
    image: tacticErza3Image,
    route: 'erza3',
  },
  'trivia-sports-jos-footy': {
    name: 'trivia-sports-jos-footy',
    type: 'trivia',
    topic: 'sports',
    partner: 'jos-footy',
    logo: triviaSportsJosFootyImage,
    image: triviaSportsJosFootyImage,
    route: 'jos-footy',
  },
  'trivia-art-beta3-aflam': {
    name: 'trivia-art-beta3-aflam',
    type: 'trivia',
    topic: 'art',
    partner: 'beta3-aflam',
    logo: triviaArtBeta3AflamImage,
    image: triviaArtBeta3AflamImage,
    route: 'beta3-aflam',
  },
} as const satisfies Record<Game, GameConfig>;
