import {isServer} from '@eksab/util';
import {create} from 'zustand';

const didUserDismiss: boolean | null = !isServer()
  ? JSON.parse(sessionStorage.getItem('did-user-dismiss-cpa') ?? 'null')
  : false;

const setDidUserDismiss = (didUserDismiss: boolean) =>
  sessionStorage.setItem('did-user-dismiss-cpa', JSON.stringify(didUserDismiss));

if (!isServer() && didUserDismiss === null) {
  setDidUserDismiss(false);
}

type State = {cpaAlertOpen: boolean; dismissCPAAlert: () => void; openCPAAlert: () => void; didUserDismiss: boolean};
export const useCPAAlertDisclosure = create<State>()(
  (set) =>
    ({
      cpaAlertOpen: false,
      didUserDismiss: !!didUserDismiss,
      dismissCPAAlert: () => {
        set({cpaAlertOpen: false});
        setDidUserDismiss(true);
      },
      openCPAAlert: () => {
        set({cpaAlertOpen: true});
      },
    }) as const,
);
