import numeral from 'numeral';

import en from './en.json';
import ar from './ar.json';

if (!numeral.locales.ar) {
  numeral.register('locale', 'ar', {
    delimiters: {
      thousands: ',',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'ألف',
      million: 'مليون',
      billion: 'مليار',
      trillion: 'ترليون',
    },
    ordinal: (number) => (number === 1 ? 'er' : 'ème'),
    currency: {
      symbol: 'ج.م',
    },
  });
}

export const messages = {en, ar};

export * from './useTranslate';
