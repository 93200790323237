import {useEffect} from 'react';
import NextLink from 'next/link';
import {Box, Container, Link, Spacer, Flex, Text} from '@chakra-ui/layout';
import {Image} from '@chakra-ui/image';
import {create} from 'zustand';

import {useTranslate} from '@eksab/i18n';

const taxID = '712-103-465';

const navRoutes = ['news', 'about-us'] as const;
const socials = [
  {name: 'facebook', url: 'https://www.facebook.com/Yallaeksab'},
  {name: 'linkedin', url: 'https://www.linkedin.com/company/eksab/'},
  {name: 'instagram', url: 'https://www.instagram.com/eksab_/'},
  {name: 'twitter', url: 'https://twitter.com/eksab_'},
];

const useFooterVisibility = create<{
  isFooterVisible: boolean;
  hideFooter: () => void;
  showFooter: () => void;
}>((set) => ({
  isFooterVisible: true,
  hideFooter: () => set({isFooterVisible: false}),
  showFooter: () => set({isFooterVisible: true}),
}));

export function AppFooter() {
  const {isFooterVisible} = useFooterVisibility();
  const t = useTranslate();

  return (
    <Container maxW="unset" px="none" display={{base: 'none', lg: 'block'}} flexShrink="0" id="footer">
      {isFooterVisible && (
        <Box h={'9rem'} display={{base: 'none', lg: 'flex'}} bg={['unset', null, null, 'black']}>
          <Container
            as="footer"
            px={{lg: 10, xl: '3.75rem'}}
            display={['none', null, null, 'flex']}
            alignItems="center"
            textAlign="center"
          >
            <Flex flexDir="column" align="center" gap="3" me={{lg: 10, xl: 16}}>
              <Link as={NextLink} href="/">
                <Image maxW="24" minW="5.5rem" flex="1" src="/logos/eksab-split.svg" alt={t('app.name')} />
              </Link>

              <Text color="white" fontSize="2xs" fontWeight="500">
                {t('app.tax-id')}:{' '}
                <span dir="auto" lang="en">
                  {taxID}
                </span>
              </Text>
            </Flex>

            <Box as="nav" display="flex" alignItems="center" gap="6">
              {navRoutes.map((route) => (
                <NextLink key={route} href={`/${route}`} passHref legacyBehavior>
                  <Link
                    color="gray.500"
                    fontWeight="500"
                    _hover={{
                      color: 'green.400',
                    }}
                  >
                    {t(`app.${route}`)}
                  </Link>
                </NextLink>
              ))}

              <Link as={NextLink} href="/partners" color="gray.500" fontWeight="500" _hover={{color: 'green.400'}}>
                {t('partners')}
              </Link>

              <Link
                href="https://yallaeksab.freshteam.com/jobs"
                target="_blank"
                color="gray.500"
                fontWeight="500"
                _hover={{
                  color: 'green.400',
                }}
              >
                {t(`app.careers`)}
              </Link>

              <NextLink href="/terms-and-conditions" passHref legacyBehavior>
                <Link color="gray.500" fontWeight="500" _hover={{color: 'green.400'}}>
                  {t('terms-and-conditions')}
                </Link>
              </NextLink>

              <NextLink href="https://monolith.eksab.com/privacy.html" passHref legacyBehavior>
                <Link color="gray.500" fontWeight="500" _hover={{color: 'green.400'}} target="_blank">
                  {t('privacy-policy')}
                </Link>
              </NextLink>

              <Link as={NextLink} href="/contact" color="gray.500" fontWeight="500" _hover={{color: 'green.400'}}>
                {t('contact.title')}
              </Link>
            </Box>

            <Box display="flex" alignItems="center" gap="2.5" mx={{lg: 8, xl: 10}}>
              {socials.map(({name, url}) => (
                <Link key={name} href={url} target="_blank" title={name} w="8">
                  <Image src={`/logos/${name}.svg`} alt={name} loading="lazy" decoding="async" />
                </Link>
              ))}
            </Box>
            <Spacer />

            <Box mt="-5"></Box>
          </Container>
        </Box>
      )}
    </Container>
  );
}

export function RemoveAppFooter() {
  const {showFooter, hideFooter} = useFooterVisibility();
  useEffect(() => {
    hideFooter();
    return () => {
      showFooter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
