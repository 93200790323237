import {Image, type ImageProps} from '@chakra-ui/image';

import {useTranslate} from '@eksab/i18n';

import coinsIcon from './coins-icon.png';

export function CoinsIcon(props: ImageProps) {
  const t = useTranslate();

  return <Image src={coinsIcon.src} alt={t('app.coins')} objectFit="contain" {...props} />;
}
