import axios from 'axios';
import {useRouter} from 'next/router';
import {useCallback} from 'react';

import {config} from '@eksab/config';
import * as cache from '@eksab/cache';
import {useAuthStore} from '@eksab/features/auth/state';

export function useLogout() {
  const router = useRouter();
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const logout = useCallback(async () => {
    mixpanel?.reset();
    await axios.post(`${config.baseURL}/api/logout`);
    cache.clearMe();
    setLoggedIn(false);
    if (router.pathname !== '/r/[prizeCode]') {
      router.reload();
    }
  }, [router, setLoggedIn]);
  return {logout};
}
