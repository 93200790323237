export const config = {
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  apiURL: process.env.NEXT_PUBLIC_API_URL,
  appEnv: process.env.NEXT_PUBLIC_APP_ENV as 'development' | 'production' | 'staging',
  fbId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
  mixpanelId: process.env.NEXT_PUBLIC_MIXPANEL_ID,
  hotjarId: process.env.NEXT_PUBLIC_HOTJAR_ID,
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  fawryPluginURL: process.env.NEXT_PUBLIC_FAWRY_PLUGIN_URL,
  fawryMerchantCode: process.env.NEXT_PUBLIC_FAWRY_MERCHANT_CODE,
  fbDomainVerificationCode: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_CODE,
  //secrets
  ssrToken: process.env._NEXT_SSR_TOKEN,
  socketURL: process.env.NEXT_PUBLIC_SOCKET_BASE_URL!,
  zendeskKey: process.env.NEXT_PUBLIC_ZENDESK_KEY,
};
