import {Icon, IconProps} from '@chakra-ui/react';

export function AwardIcon(props: IconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.25 9.5C10.25 8.5335 11.0335 7.75 12 7.75C12.9665 7.75 13.75 8.5335 13.75 9.5C13.75 10.4665 12.9665 11.25 12 11.25C11.0335 11.25 10.25 10.4665 10.25 9.5Z"
        fill="#2F2F2F"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.5C5 5.63401 8.13401 2.5 12 2.5C15.866 2.5 19 5.63401 19 9.5C19 10.8722 18.6052 12.1522 17.9229 13.2325L20.6495 17.9551C20.7967 18.2101 20.7812 18.5276 20.6097 18.767C20.4382 19.0063 20.1425 19.1231 19.8537 19.0657L17.2376 18.5455L16.3801 21.0713C16.2854 21.35 16.0364 21.5477 15.7434 21.5765C15.4504 21.6054 15.1676 21.4601 15.0204 21.2051L12.3002 16.4937C12.2007 16.4979 12.1006 16.5 12 16.5C11.8994 16.5 11.7993 16.4979 11.6998 16.4937L8.97965 21.2051C8.83258 21.4599 8.5502 21.6051 8.25745 21.5766C7.96471 21.5481 7.71564 21.3511 7.62047 21.0728L6.75854 18.5522L4.14467 19.066C3.85606 19.1228 3.56093 19.0056 3.38988 18.7663C3.21882 18.527 3.20342 18.2099 3.35048 17.9551L6.07708 13.2325C5.39483 12.1522 5 10.8722 5 9.5ZM7.08592 14.4852C7.91817 15.3056 8.95488 15.9192 10.1127 16.2426L8.50579 19.0259L7.96599 17.4473C7.84463 17.0924 7.47972 16.8817 7.11167 16.9541L5.4747 17.2759L7.08592 14.4852ZM13.8873 16.2426L15.4918 19.0217L16.0287 17.4406C16.1497 17.0842 16.516 16.8727 16.8851 16.9461L18.5229 17.2717L16.9141 14.4852C16.0818 15.3056 15.0451 15.9192 13.8873 16.2426ZM12 6.25C10.2051 6.25 8.75 7.70507 8.75 9.5C8.75 11.2949 10.2051 12.75 12 12.75C13.7949 12.75 15.25 11.2949 15.25 9.5C15.25 7.70507 13.7949 6.25 12 6.25Z"
        fill="#2F2F2F"
      />
    </Icon>
  );
}
