import {api, setApiToken} from '@eksab/api';
import {config} from '@eksab/config';

import {QueryClient, useQuery} from '@tanstack/react-query';
import axios from 'axios';

import {useLogout} from './useLogout';
import {useAuthStore} from '@eksab/features/auth/state';

const queryKey = ['access-token'];
export function useAccessToken() {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const {logout} = useLogout();
  return useQuery<string>(queryKey, () => api.get(`${config.baseURL}/api/access-token`).then((res) => res.data.token), {
    retry: 0,
    enabled: isLoggedIn,
    staleTime: Infinity,
    onSuccess: (token) => {
      setApiToken(token);
    },
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        logout();
      }
    },
  });
}

export function useSetAccessToken() {
  const queryClient = new QueryClient();
  function setAccessToken(accessToken: string | undefined) {
    queryClient.setQueryData(queryKey, accessToken);
  }
  return setAccessToken;
}

useAccessToken.queryKey = queryKey;
