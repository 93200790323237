import {useIntl} from 'react-intl';
import en from './en.json';
import ar from './ar.json';

export type I18nKey = keyof typeof en & keyof typeof ar;

export function useTranslate() {
  const formatMessage = useIntl().formatMessage;
  type restParams = Parameters<typeof formatMessage>;
  const translate = (id: I18nKey, formatXMLElementFn?: restParams[1]) =>
    formatMessage({id}, formatXMLElementFn) as string;
  return translate;
}

export type T = ReturnType<typeof useTranslate>;
