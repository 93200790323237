import {ChakraTheme, extendTheme} from '@chakra-ui/react';

export const px = [4, 6, 12, 32] as const satisfies number[];

//  Pages unified y padding
export const py = [6, 8, 10, 12] as const satisfies number[];

const pInRems = px.map((v) => v && (`${v / 4}rem` as const));

//  -(paddingX)
export const npx = px.map((v) => v && `calc(${-v / 4}rem)`);

export const breakPoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '90em', // 1440px
  '2xl': '96em', // 1536px
};

const colors = {
  red: {
    400: '#ec1958',
    600: '#CB2525',
  },
  // gray.200 is black with 15% opacity but flattened. It's frequent in the design
  gray: {
    200: '#DBDBDB',
    300: '#BBB',
    400: '#DFDFDF',
    500: '#ABABAB',
    600: '#F4F4F4',
    700: '#313131',
    800: '#343434',
    900: '#131313',
  },
  green: {
    50: '#eef9f2',
    400: '#5AC57B',
    500: '#244F31',
  },
  yellow: {
    50: '#fff7e6',
    300: '#F4D39F',
    400: '#FEE182',
  },
  blue: {
    400: '#1448ff',
  },
  black: '#101010',
  gold: '#E2B647',
  sliver: 'gray.500',
};

const shadows = {
  xs: '0px 0px 15px rgba(16, 16, 16, 0.1)',
  sm: '0px 0px 20px rgba(27, 27, 27, 0.1)',
  md: '0px 0px 20px rgba(27, 27, 27, 0.15)',
  'inner-sm': 'inset 0px 0px 10px 4px rgba(16, 16, 16, 0.15)',
  'inner-md': 'inset 0px 0px 10px 4px rgba(16, 16, 16, 0.25)',
};

const radii = {xs: '0.25rem', sm: '0.375rem', md: '0.625rem'};

const Card: ChakraTheme['components']['Card'] = {
  baseStyle: {
    borderRadius: 'md',
    bgColor: 'white',
    shadow: 'md',
    flexShrink: 0,
  },
};

const Button: ChakraTheme['components']['Button'] = {
  baseStyle: {
    fontWeight: 'black',
    lineHeight: 1,
    borderRadius: '50px',
    _focus: {
      boxShadow: 'unset',
    },
  },
  variants: {
    solid: {
      fontSize: 'lg',
      px: '1.5625rem',
      bgColor: 'green.400',
      textTransform: 'capitalize',
      color: '#FFF',
      _hover: {
        boxShadow: 'inner-md',
        transitionDuration: 'normal',
      },
    },
    outline: {
      textTransform: 'capitalize',
      bg: '#FFF',
      color: 'green.400',
      border: '2px solid',
      borderColor: 'green.400',
      _hover: {
        bg: '#FFF',
        boxShadow: 'inner-sm',
      },
    },
    black: {
      height: 10,
      minWidth: 24,
      bgColor: 'white',
      borderWidth: 1,
      borderColor: 'gray.300',
      flexShrink: 0,
      lineHeight: 6,
      fontSize: '0.875rem',
      transitionDuration: 'normal',
      px: [4, null, 6],
      py: 2,
      borderRadius: 'sm',
      color: 'gray.500',
      fontWeight: '500',
      textTransform: 'capitalize',
      _active: {
        bgColor: 'black',
        color: 'white',
        fontWeight: '800',
        borderColor: 'transparent',
      },
    },
    warn: {
      fontSize: 'lg',
      bgColor: 'white',
      textTransform: 'capitalize',
      color: 'red.600',
      border: '2px solid',
      borderColor: 'red.600',
      _hover: {
        bgColor: 'white',
        shadow: 'inner-sm',
      },
      _active: {
        '&:not(:disabled)': {
          bg: 'red.600',
          color: 'white',
        },
      },
    },
    secondary: {
      minH: 'unset',
      py: 1,
      px: 4,
      minW: 20,
      fontSize: 'md',
      fontWeight: '800',
      height: 'unset',
      lineHeight: 2,
      bgColor: 'white',
      color: 'gray.500',
      border: '1px',
      borderColor: 'gray.500',
      borderRadius: '7px',
      _active: {
        bgColor: 'green.400',
        borderColor: 'black',
        color: 'black',
      },
    },
  },
  sizes: {
    md: {
      h: '50px',
    },
  },
  defaultProps: {
    colorScheme: 'green',
  },
};

const Heading = {
  baseStyle: {
    fontWeight: 'black',
    color: 'black',
  },
  sizes: {
    '4xl': {
      fontSize: 'clamp(2em, 5vw, 4em)',
      lineHeight: 1.2,
    },
    '2xl': {
      fontSize: 'clamp(1.5em, 5vw, 2.25em)',
      lineHeight: [1.2, null, 1.4],
      color: 'gray.900',
    },
    xl: {
      fontSize: ['1.125rem', null, '1.25rem', '1.5rem'],
      fontWeight: 700,
      lineHeight: 1.5,
      color: 'black',
    },
  },
};

const Text: ChakraTheme['components']['Text'] = {
  baseStyle: {
    color: 'black',
  },
};

const Container: ChakraTheme['components']['Container'] = {
  baseStyle: {
    maxW: '8xl',
    display: 'flex',
    px,
  },
  variants: {
    'pattern-light': {
      bg: 'url(/patterns/pattern-light.svg)',
    },
    'pattern-dark': {
      bg: 'url(/patterns/pattern-dark.svg)',
      bgColor: 'black',
      bgBlendMode: 'overlay',
    },
    scroll: {
      w: 'unset',
      scrollbarWidth: 'none',
      msOverFlowStyle: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      mx: npx,
      px,
      overflowX: 'scroll',
      overflowInline: 'auto',
      overscrollBehaviorInline: 'contain',
      scrollSnapType: 'inline mandatory',
      scrollPaddingInline: pInRems,
      '& > *': {
        scrollSnapAlign: 'start',
      },
    },
  },
};

const Modal: ChakraTheme['components']['Modal'] = {
  parts: ['content'],
  variants: {
    full: {
      dialog: {
        maxH: '80vh',
        overflowY: 'auto',
        m: 0,
        alignSelf: ['flex-end', 'center'],
        [`@media (max-width: ${breakPoints.sm})`]: {
          roundedBottom: 0,
          maxW: 'unset',
          pos: 'fixed',
          bottom: 0,
        },
      },
    },
  },
};

const CloseButton: ChakraTheme['components']['CloseButton'] = {
  baseStyle: {
    _focus: {
      shadow: 'unset',
    },
  },
};

const Switch: ChakraTheme['components']['Switch'] = {
  baseStyle: {
    track: {
      _checked: {
        bg: 'green.400',
      },
    },
  },
};

const Link = {
  baseStyle: {
    color: 'white',
    fontSize: 'sm',
    fontWeight: '500',
    _focus: {
      boxShadow: 'unset',
    },
  },
};

const inputDefaultProps = {
  focusBorderColor: 'green.400',
};

const Input = {
  defaultProps: inputDefaultProps,
  sizes: {
    md: {
      field: {
        borderRadius: 'sm',
        '::placeholder': {
          color: 'gray.500',
        },
      },
    },
  },
};

const NumberInput = {
  variants: {
    outline: {
      field: {outlineColor: 'green.400'},
    },
  },
};

const Select = {
  defaultProps: inputDefaultProps,
};

const Table = {
  variants: {
    striped: {
      tbody: {
        tr: {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: 'gray.400',
            backgroundColor: 'gray.800',
          },
          '&:nth-last-of-type(even) td': {
            background: 'gray.600',
          },
          '&:nth-last-of-type(odd) td': {
            background: 'white',
          },
        },
      },
    },
  },
};

export const theme = (direction: 'rtl' | 'ltr') =>
  extendTheme({
    direction,
    breakPoints,
    styles: {
      global: {
        html: {
          scrollBehavior: 'smooth',
        },
        '#__next': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
        '#nprogress .bar': {
          background: 'green.400',
        },
        '#nprogress .spinner': {
          right: 'auto',
          insetInlineEnd: '4',
        },
        '#nprogress .spinner-icon': {
          borderTopColor: 'green.400',
          borderLeftColor: 'green.400',
        },
        body: {
          bg: 'white',
          color: 'black',
        },
        '.grecaptcha-badge': {
          visibility: 'hidden',
        },
        '[lang^="ar"]': {
          fontFamily: 'Cairo',
        },
        '[lang^="en"]': {
          fontFamily: 'Poppins',
        },
      },
    },
    colors,
    fonts: {
      body: direction === 'rtl' ? 'Cairo' : 'Poppins',
      heading: direction === 'rtl' ? 'Cairo' : 'Poppins',
    },
    components: {
      Container,
      Modal,
      CloseButton,
      Heading,
      Text,
      Button,
      Link,
      Card,
      Input,
      NumberInput,
      Select,
      Table,
      Switch,
    },
    shadows,
    radii,
  });
