import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useRouter} from 'next/router';
import {Alert, AlertIcon, AlertDescription} from '@chakra-ui/alert';
import {CloseButton} from '@chakra-ui/close-button';
import {useBreakpointValue} from '@chakra-ui/media-query';

import {useTranslate} from '@eksab/i18n';
import {useAppSettingsQuery} from '@eksab/hooks/useAppSettingsQuery';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {useCPAAlertDisclosure} from '@eksab/hooks/useCPALicenseState';

export const CPALicense = () => {
  const t = useTranslate();
  const {cpaAlertOpen, dismissCPAAlert, didUserDismiss, openCPAAlert} = useCPAAlertDisclosure();
  const locale = useRouter().locale;
  const {data: regionSettings} = useRegionSettings();
  const license = useAppSettingsQuery().data?.licence_details;
  const cpaAlertHeight = useCPAAlertHeight();

  const format = useIntl().formatNumber;
  const formatDate = useIntl().formatDate;

  useEffect(() => {
    if (!didUserDismiss) {
      if (!!regionSettings?.settings?.show_licence_details) openCPAAlert();
    }
  }, [didUserDismiss, locale, openCPAAlert, regionSettings?.settings?.show_licence_details]);

  if (regionSettings?.iso_code !== 'EG') {
    return (
      <Alert
        status="info"
        h="auto"
        maxH={cpaAlertOpen ? '8rem' : '0rem'}
        py={cpaAlertOpen ? 1 : 0}
        transition="max-height 0.2s ease-out"
        overflow="hidden"
      >
        <AlertDescription ms="auto" alignItems="center" fontSize={['xs', null, 'md']} fontWeight="500" lineHeight="1.5">
          {t('app.beta')}
        </AlertDescription>

        <CloseButton size={['sm', null, 'md']} ms="auto" onClick={dismissCPAAlert} />
      </Alert>
    );
  }

  if (!license) return null;

  return (
    <Alert
      status="success"
      h="auto"
      maxH={cpaAlertHeight}
      py={cpaAlertOpen ? 1 : 0}
      transition="max-height 0.2s ease-out"
      overflow="hidden"
    >
      <AlertIcon ms="auto" />
      <AlertDescription alignItems="center">
        {t('app.cpa-license', {
          b: (chunk) => <b>{chunk}</b>,
          number: format(+license.number),
          date: formatDate(license.date, {month: 'short', year: 'numeric'}),
        })}
      </AlertDescription>
      <CloseButton onClick={dismissCPAAlert} ms="auto" />
    </Alert>
  );
};

export function useCPAAlertHeight() {
  const cpaAlertOpen = useCPAAlertDisclosure((state) => state.cpaAlertOpen);
  const cpaAlertHeight = useBreakpointValue(['3.5rem', null, null, '2.5rem'])!;

  return cpaAlertOpen ? cpaAlertHeight : '0rem';
}
