import {type ReactNode, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {Box, Text} from '@chakra-ui/layout';
import type {IconProps} from '@chakra-ui/icon';
import {motion} from 'framer-motion';
import {create} from 'zustand';

import {px} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';
import {useWebViewData} from '@eksab/hooks/useWebView';
import {useAccessToken} from '@eksab/hooks/useAccessToken';

import {NewBadge} from '../NewBadge';
import {icons} from './icons';
import {useMobileNavHeight} from '../footer/useMobileNavHeight';

const useNavBarVisibility = create<{
  isNavBarVisible: boolean;
  hideNavBar: () => void;
  showNavBar: () => void;
}>((set) => ({
  isNavBarVisible: true,
  hideNavBar: () => set({isNavBarVisible: false}),
  showNavBar: () => set({isNavBarVisible: true}),
}));

export const AppMobileNav = () => {
  const router = useRouter();

  const t = useTranslate();
  const {webViewData} = useWebViewData();
  const isLoggedIn = useAccessToken().isSuccess;
  const isVisible = useNavBarVisibility((state) => state.isNavBarVisible && !webViewData?.hideNavbar);

  return (
    <Box id="mob-nav" inset={0} top="unset" zIndex="sticky" pos="fixed">
      {isVisible && (
        <Box
          as="nav"
          h={'4.7rem'}
          boxShadow="0px -8px 40px rgba(0, 0, 0, 0.15);"
          bgColor="black"
          borderTopRadius="20"
          px={px}
          alignItems="center"
          justifyContent="space-between"
          display={['flex', null, null, 'none']}
        >
          <MobileNavRoute name={t('app.home')} route="/" icon={icons.Home} isActive={router.route === '/'} />

          <MobileNavRoute
            name={t('play')}
            route="/play"
            icon={icons.TriviaIcon}
            isActive={
              router.route.startsWith('/play') ||
              router.route.startsWith('/fantasy') ||
              router.route.startsWith('/predictions')
            }
          >
            <NewBadge size="sm" top="-0.5" insetEnd="0" />
          </MobileNavRoute>

          <MobileNavRoute
            name={t('app.coins')}
            route="/store/coins"
            icon={icons.StoreIcon}
            isActive={router.route.startsWith('/store')}
          />

          <MobileNavRoute
            name={t('app.profile')}
            route={isLoggedIn ? '/profile' : '/login?from=/profile'}
            icon={icons.ProfileIcon}
            isActive={router.route.startsWith('/profile')}
          />
        </Box>
      )}
    </Box>
  );
};

export const MobileNav = ({children}: {children?: ReactNode}) => {
  const [mobNavNode, setMobNavNode] = useState<HTMLElement>();
  const setBottomNavHeight = useMobileNavHeight((state) => state.setBottomNavHeight);
  const resetBottomNavHeight = useMobileNavHeight((state) => state.reset);
  const {showNavBar, hideNavBar} = useNavBarVisibility();
  useEffect(() => {
    hideNavBar();
    const mobNav = document.getElementById('mob-nav');
    if (mobNav) {
      setMobNavNode(mobNav);
    }
    return () => {
      showNavBar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mobNavNode) return;
    mobNavNode.children.length
      ? setBottomNavHeight(`${mobNavNode.children[0].clientHeight}px`)
      : setBottomNavHeight('0px');
    return () => resetBottomNavHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobNavNode]);

  return mobNavNode ? createPortal(children, mobNavNode) : null;
};

interface NavRoute {
  name: string;
  route: string;
  icon: (props: IconProps) => JSX.Element;
  isActive?: boolean;
  children?: ReactNode;
}

const MobileNavRoute = ({route, name, icon: Icon, isActive, children}: NavRoute) => (
  <Box
    as={NextLink}
    href={route}
    pos="relative"
    display="flex"
    flexDir="column"
    gap="0.5"
    alignItems="center"
    cursor="pointer"
    minW="50px"
  >
    <Box pos="relative">
      <Icon boxSize="6" stroke="gray.500" fill="none" />

      {isActive && (
        <Box as={motion.div} pos="absolute" inset={0} animate={{opacity: 1}} initial={{opacity: 0}}>
          <Icon boxSize="6" d="block" stroke="green.400" fill="green.400" color="green.400" />
        </Box>
      )}
    </Box>

    <Text
      align="center"
      textTransform="capitalize"
      fontSize="xs"
      color={isActive ? 'green.400' : 'gray.500'}
      fontWeight="600"
    >
      {name}
    </Text>

    {children}
  </Box>
);
