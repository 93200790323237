import {Icon, type IconProps} from '@chakra-ui/icon';

export const PredictionsIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M13.9965 10.3333C13.9965 12 13.9965 16 13.9965 17.6667C14.0553 19.5 13.3798 20 11.9997 20C10.598 20 9.95013 19.5 10.003 17.6667C10.003 16 10.003 12 10.003 10.3333C10.003 9 10.003 8 11.9997 8C13.9965 8 13.9965 9 13.9965 10.3333Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 15.5278C7 16.5 7 17.079 7 18.2943C7 19.5096 6.38228 20 5 20C3.59603 20 3 19.5096 3 18.2943C3 17.3221 3 16.5 3 15.5278C3 13.875 2.99999 13 5 13C7.00001 13 6.94107 13.875 7 15.5278Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 6.77778C21 9 21 15 21 17.2222C21 20 20.3823 20 19 20C17.596 20 17 20 17 17.2222C17 15 17 9 17 6.77778C17 4.55556 17 4 19 4C21 4 21 4.55556 21 6.77778Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
