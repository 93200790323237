import axios, {AxiosError} from 'axios';
import {QueryClient, useQueryClient, useQuery, UseQueryOptions, QueryFunctionContext} from '@tanstack/react-query';

import {api} from '@eksab/api';
import * as cache from '@eksab/cache';

import {useAccessToken} from './useAccessToken';
import {useLogout} from './useLogout';

type Params = {
  getStats: boolean;
  getPowersCount: boolean;
};

type MeQueryParams = 'all' | Partial<Params> | undefined;

type MeQueryReturn<T extends MeQueryParams> = T extends 'all' | {[K in keyof Params]: true}
  ? ExtendedMe
  : T extends {getStats: true}
    ? Me & Pick<ExtendedMe, 'winnings_stats' | 'participation_stats'>
    : T extends {getPowersCount: true}
      ? Me & Pick<ExtendedMe, 'powers_count'>
      : Me;

const queryKey = <T extends MeQueryParams>(params?: T) => ['me', params ?? null] as const;

type QueryKey = ReturnType<typeof queryKey>;

const fetchMe = async <T extends MeQueryParams>({queryKey: [, params]}: QueryFunctionContext<QueryKey>) => {
  const shouldGetAllParams = params === 'all';

  const me = (
    await api.get<{data: MeQueryReturn<T>}>('/v2/profile/me', {
      params: {
        stats: shouldGetAllParams || params?.getStats,
        'powers-count': shouldGetAllParams || params?.getPowersCount,
      },
    })
  ).data.data;

  cache.setMe(me);
  return me;
};

export function useMeQuery<T extends MeQueryParams = undefined>(
  params?: T,
  options?: UseQueryOptions<MeQueryReturn<T>, AxiosError, MeQueryReturn<T>, QueryKey>,
) {
  const token = useAccessToken().data;
  const {logout} = useLogout();

  return useQuery<MeQueryReturn<T>, AxiosError, MeQueryReturn<T>, QueryKey>(queryKey(params), fetchMe, {
    enabled: !!token,
    onError: (error) => {
      if (axios.isAxiosError(error) && error.response?.status && [401, 403].includes(error.response.status)) {
        logout();
      }
    },
    ...options,
  });
}

export function setMeQueryData(queryClient: QueryClient, me: Me | undefined) {
  queryClient.setQueryData(queryKey(), me);
}

export function useSetMeQueryData() {
  const client = useQueryClient();
  return (me: Me | undefined) => setMeQueryData(client, me);
}

export function useGetMeQueryData() {
  const client = useQueryClient();
  return () => client.getQueryData<Me>(queryKey(), {exact: false});
}

export function useFetchMe() {
  const queryClient = useQueryClient();
  return () => queryClient.fetchQuery<Me>(queryKey());
}

export function useInvalidateMe() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<Me>(queryKey());
}

useMeQuery.queryKey = queryKey;
useMeQuery.queryFn = fetchMe;
