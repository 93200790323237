import Script from 'next/script';

export const MetaPixelScript = () => (
  <Script
    id="meta-pixel"
    strategy="lazyOnload"
    dangerouslySetInnerHTML={{
      __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '621754899813815');
      fbq('init', '238989962477088');
      fbq('init', '637719408450791');
      fbq('track', 'PageView');
      `,
    }}
  />
);
