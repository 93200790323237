import Script from 'next/script';

export const GooglePixelScript = () => (
  <>
    <Script src="https://www.googletagmanager.com/gtag/js?id=G-8BQHSM7ETT" strategy="lazyOnload" />
    <Script
      id="google-pixel"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-8BQHSM7ETT');
        `,
      }}
    />
  </>
);
