import {useRouter} from 'next/router';
import {QueryFunction, QueryKey, useQuery as useReactQuery, UseQueryOptions} from '@tanstack/react-query';
export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
) {
  const {locale} = useRouter();
  const key =
    typeof queryKey === 'string'
      ? [queryKey, locale ?? 'en']
      : [...(queryKey.map((key) => key ?? null) as []), locale ?? 'en'];
  return useReactQuery<TQueryFnData, TError, TData, TQueryKey>(key as unknown as TQueryKey, queryFn, options);
}
